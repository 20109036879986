@import "./_mantine.scss";
.deleteButton {
  border: 1px solid var(--mantine-color-pink-5);
  color: var(--mantine-color-pink-5);
  background-color: white;
  &:hover {
    border: 1px solid var(--mantine-other-pinkHover);
    color: var(--mantine-other-pinkHover);
    background-color: white;
  }
}
.cancelButton {
  background-color: var(--mantine-color-pink-6);
  color: white;
  border: none;
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  &:hover {
    background-color: var(--mantine-other-pinkHover);
  }
}
