@import "./_mantine.scss";
.backButton {
  position: fixed;
  top: 18px;
  left: 20px;
  z-index: 99999;

  @media (min-width: $mantine-breakpoint-sm) {
    left: 16px;
  }
}

.title {
  margin-top: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
  @media (min-width: $mantine-breakpoint-sm) {
    margin-bottom: 12px;
  }
}

.copy {
  p {
    font-size: var(--mantine-font-size-sm);
    line-height: 18px;
  }
  margin-bottom: 32px;
}

.wrapper {
  @media (min-width: $mantine-breakpoint-sm) {
    width: 525px;
    margin: 0 auto;
  }
}

.activitiesSelected {
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;
  line-height: 16px;
}
.warningWrapper {
  background-color: var(--mantine-color-blue-2);
  padding: 8px 12px;
  margin-block: 32px;
  border-radius: 4px;
}
.warningCopy {
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
  line-height: 16px;
  color: var(--mantine-color-blue-8);
  margin-left: 8px;
}

.endOfList {
  color: var(--mantine-color-gray-5);
  font-size: var(--mantine-font-size-sm);
  line-height: 18px;
  text-align: center;
}

.activityCardWrapper {
  > p {
    margin-top: 24px;
    & + .selectAllCheckbox {
      margin-top: 32px;
    }
  }

  > .selectAllCheckbox {
    margin-top: 24px;
  }
}

.noActivities {
  padding: 32px 0;
  text-align: center;
  width: 100%;
  color: var(--mantine-color-gray-5);
  font-size: var(--mantine-font-size-md);
  font-weight: 600;
  line-height: 22px;
  border: 1px solid var(--mantine-color-gray-5);
  border-radius: 12px;
}

.skeletonWrapper {
  box-shadow: 0px 4px 24px 0px #0b174e1a;
  border-radius: 8px;
  padding: 12px;
  gap: 12px;
}
