@import "./_mantine.scss";
.envelopeIcon {
  margin-top: var(--mantine-spacing-md);
  margin-bottom: var(--mantine-spacing-md);
  @media (max-width: $mantine-breakpoint-sm) {
    margin-top: var(--mantine-spacing-lg);
  }
}

.copy {
  color: var(--mantine-color-blue-6);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-bottom: 32px;
  @media (max-width: $mantine-breakpoint-sm) {
    width: 245px;
  }
}

.inviteButtons {
  background: var(--White, #fff);
  box-shadow: 0px 4px 24px 0px rgba(11, 23, 78, 0.1);
  color: var(--mantine-color-blue-8);
  border: 1px solid var(--mantine-color-blue-8);
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  border-radius: 8px;
  margin-bottom: 40px;
  height: 44px;
  &:hover {
    background-color: #e7e9f1;
    color: var(--mantine-color-blue-8);
  }
}

.inviteButtonsInner {
  display: flex;
  justify-content: space-between;
}

.dividerContainer {
  display: flex;
  align-items: center;
  width: 279px;
  margin: 0 auto;
}

.divider {
  flex: 1;
  height: 1px;
  background-color: var(--mantine-color-gray-4);
}

.text {
  padding: 0 24px;
  font-size: 14px;
}
