@import "./_mantine.scss";
.centerWrapper {
  display: flex;
  flex-direction: column;
}
.termDates {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--mantine-color-blue-1);
  padding: var(--mantine-spacing-md);
  margin-right: -24px;
  margin-left: -24px;
}
.termsContainer {
  background-color: white;
  padding-left: var(--mantine-spacing-xl);
  padding-right: var(--mantine-spacing-xl);
  @media (max-width: 768px) {
    padding: 0;
  }
}
.formWrapper {
  border-radius: var(--mantine-radius-md);
  padding: 0 16px;
  @media (max-width: $mantine-breakpoint-md) {
    border-radius: 0;
    padding: var(--mantine-spacing-lg);
  }
  margin: 0;
}
.sectionWrapper {
  @media (min-width: $mantine-breakpoint-sm) {
    padding: var(--mantine-spacing-lg) var(--mantine-spacing-xl);
    box-shadow: 0px 4px 24px 0px #00000014;
    border-radius: 24px;
    margin-bottom: 32px;
  }
}
.disabledSectionWrapper {
  position: relative;
  text-align: center;
  overflow: hidden;
}
.notification {
  background-color: var(--mantine-color-blue-5);
  border-radius: var(--mantine-spacing-xs);
}
.notificationTitle {
  font-size: var(--mantine-font-size-md);
  font-weight: bold;
  color: white;
}
.notificationMessage {
  color: white;
}
.notificationClose {
  color: white;
  background-color: var(--mantine-color-blue-6);
  align-self: flex-start;
  border-radius: var(--mantine-radius-md);
  &:hover {
    color: var(--mantine-color-gray-8);
  }
}
.notificationIcon {
  margin-left: var(--mantine-spacing-xs);

  svg {
    color: white;
  }
}

// term viewing modal styling
.modal {
  text-align: center;
}
.modalHeader {
  display: none;
}
.modalTitle {
  padding: var(--mantine-spacing-lg) 60px;
  color: var(--mantine-color-blue-5);
}

// calendar styling

.calendarWrapper {
  background-color: white;
  padding: var(--mantine-spacing-lg);
  border-radius: var(--mantine-radius-xs);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  display: inline-block;
  margin: var(--mantine-spacing-md) auto var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xs);
}
.day {
  border-top: none;
  border-radius: 0px;
  button {
    color: var(--mantine-color-blue-8);
    border-radius: 0px;
  }
  &:hover {
    color: var(--mantine-color-blue-8) !important;
    background-color: var(--mantine-color-gray-2) !important;
  }
  &[data-weekend] {
    color: var(--mantine-color-blue-8) !important;
    &:disabled {
      color: #ced4da !important;
    }
  }
  &[data-selected] {
    background-color: var(--mantine-color-gray-2) !important;
    color: var(--mantine-color-blue-8) !important;
  }
  &[data-outside] {
    button {
      color: var(--mantine-color-blue-8) !important;
    }
  }
  &[data-today] {
    font-weight: 700;
  }
}
.weekendWhite {
  &[data-weekend] {
    color: white !important;
    &:hover {
      color: var(--mantine-color-blue-8) !important;
    }
    &[data-selected] {
      color: var(--mantine-color-blue-8) !important;
    }
  }
}
.dayTermViewingModal {
  border-top: none;
  border-radius: 0px;
  button {
    color: var(--mantine-color-blue-8);
    border-radius: 0px;
  }
  &[data-weekend] {
    color: white !important;
    &:disabled {
      color: #ced4da !important;
    }
  }
  &[data-selected] {
    background-color: var(--mantine-color-gray-2) !important;
    color: var(--mantine-color-blue-8) !important;
  }
}
.termDateInput {
  border: none;
  background-color: var(--mantine-color-gray-2);
}
.dayDateInput {
  &[data-selected] {
    background-color: var(--mantine-color-blue-8);
    color: white !important;
    &:hover {
      background-color: var(--mantine-color-blue-8) !important;
    }
  }
  &[data-weekend] {
    color: var(--mantine-color-blue-8) !important;
    &[data-selected] {
      color: white !important;
    }

    &:disabled {
      color: #ced4da !important;
    }
  }
  &[data-today] {
    font-weight: 700;
  }
}
.boundaryDay {
  background-color: var(--mantine-color-blue-8);
  color: white !important;
  &:hover {
    color: var(--mantine-color-blue-8) !important;
  }
}
.betweenDay {
  background-color: #445690;
  color: white;
}
.selectedDay {
  background-color: #e7eeff !important;
}
.outside {
  button {
    color: var(--mantine-color-blue-8) !important;
  }
}
.calendarKey {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: var(--mantine-spacing-md);
  max-width: 322px;
  @media (max-width: 768px) {
    max-width: 266px;
  }
  div {
    margin: 0 8px 0 0;
  }
}
.flexKey {
  display: flex;
  align-items: center;
}
.termDateKey {
  background-color: #445690;
  border-radius: 4px;
  height: 15px;
  width: 15px;
}
.offDaysKey {
  background-color: var(--mantine-color-gray-2);
  border-radius: 4px;
  height: 15px;
  width: 15px;
}
.disabled {
  color: var(--mantine-color-blue-8);
  opacity: 1;
  pointer-events: none;
  & .mantine-TimeInput-disabled {
    opacity: 1;
    font-weight: lighter;
  }
  & .mantine-TimeInput-input {
    background-color: transparent;
  }
  & input:disabled {
    color: var(--mantine-color-blue-8);
  }
}
