@import "./_mantine.scss";
@keyframes fadeInAnimation {
  from {
    background-color: var(--mantine-color-lime-1);
  }
  to {
    background-color: var(--mantine-color-gray-1);
  }
}

.table {
  border-collapse: separate;
  border-spacing: 0 8px;
  thead tr th {
    text-align: left;
    padding: var(--mantine-spacing-sm) 0 var(--mantine-spacing-xs) var(--mantine-spacing-xs);
    font-size: var(--mantine-font-size-xs);
    color: var(--mantine-color-blue-8);
    font-weight: 400;
    border-bottom: none;
    border-top: 1px solid var(--mantine-color-gray-3);
  }
  tbody tr td {
    border-top: none;
    font-size: var(--mantine-font-size-sm);
    font-weight: 600;
    color: var(--mantine-color-blue-8);
    border-bottom: none;
    padding-top: var(--mantine-spacing-sm);
    padding-bottom: var(--mantine-spacing-sm);
    padding-left: var(--mantine-spacing-sm);
    background-color: var(--mantine-color-gray-1);
    margin-bottom: 4px;
    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  }

  tbody tr:hover {
    box-shadow: 0px 1px 5px 0px #00000052;
    border-radius: 4px;
    cursor: pointer;
  }
}

.tdNoResult {
  height: calc(100vh - 135px - 90px);
  tbody tr td {
    background-color: white;
  }
  tr:hover {
    box-shadow: none !important;
    cursor: default !important;
  }
}
