@import './variables';

// Typography
// Style guide: figma.com/file/uLqzoDP5GiMZU9MMVIfErS/Colors-%26-Typography?node-id=0%3A1

// Variables
$fontFamilyDefault: 'Mulish', sans-serif;

$fontSizeDefault: 16px;
$lineHeightDefault: 22px;
$fontColorDefault: $dark600;

/**
 * Builds the styles necessary to adhere to our typography styleguide.
 * All typography works off of a scale system, which a number between
 * 7 (largest) and -1 (smallest), with 1 being the default.
 */
@mixin typography($scale: 1, $fontWeight: 400) {
  $fontSize: $fontSizeDefault;
  $lineHeight: $lineHeightDefault;

  @if ($scale == 7) {
    $fontSize: 48px;
    $lineHeight: 54px;
  } @else if ($scale == 6) {
    $fontSize: 32px;
    $lineHeight: 40px;
  } @else if ($scale == 5) {
    $fontSize: 24px;
    $lineHeight: 36px;
  } @else if ($scale == 4) {
    $fontSize: 22px;
    $lineHeight: 28px;
  } @else if ($scale == 3) {
    $fontSize: 20px;
    $lineHeight: 24px;
  } @else if ($scale == 2) {
    $fontSize: 18px;
    $lineHeight: 24px;
  } @else if ($scale == 1) {
    $fontSize: 16px;
    $lineHeight: 22px;
  } @else if ($scale == 0) {
    $fontSize: 14px;
    $lineHeight: 18px;
  } @else if ($scale == -1) {
    $fontSize: 12px;
    $lineHeight: 16px;
  } @else {
    // Invalid scale used, throw and error
    @error "Invalid `$scale` provided to `typography()`. Value must be between 7 and -1, but received '#{$scale}'";
  }

  font: #{$fontWeight} #{$fontSize}/#{$lineHeight} $fontFamilyDefault;

  @if ($scale <= 2) {
    // Smaller font-sizes have a more condensed letter spacing
    letter-spacing: 0.01em;
  }
}

@mixin typographySemibold($scale: 1) {
  @include typography($scale, 600);
}

@mixin typographyBold($scale: 1) {
  @include typography($scale, 700);
}
