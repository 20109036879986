@import "./_mantine.scss";
.input {
  width: 63%;
  margin-top: var(--mantine-spacing-md);
  margin-bottom: var(--mantine-spacing-md);
  margin-left: var(--mantine-spacing-md);
}

.item {
  &[data-combobox-active] {
    border: 4px solid #fb54ae;
    background-color: var(--mantine-color-gray-4);
  }
}
.item:nth-of-type(odd) {
  background-color: #5565a9;
  color: white;
}

.label {
  color: var(--mantine-color-blue-8);
  padding-bottom: var(--mantine-spacing-xs);
  font-size: var(--mantine-font-size-md);
}
