@import "./_mantine.scss";
.filterCard {
  width: 100%;
  height: fit-content;
  overflow: auto;
  background-color: white;
  padding: 16px 12px 0 12px;

  @media (max-width: $mantine-breakpoint-sm) {
    width: 100%;
    maxheight: 100%;
  }
}

.divider {
  color: var(--mantine-color-gray-1);
  opacity: 0.3;
}
