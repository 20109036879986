@import "./_mantine.scss";
.radioLabel {
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-blue-8);
  font-weight: 600;
}

.radioRoot {
  padding: var(--mantine-spacing-xs) 0;
}

.radio {
  border: var(--mantine-color-blue-5) 2px solid;

  &:checked {
    background-color: var(--mantine-color-lime-5);
    border: var(--mantine-color-blue-5) 7px solid;
    height: 20px;
    width: 20px;
    z-index: 2;
    cursor: pointer;
  }
}

.variantDescription {
  color: var(--mantine-color-blue-8);
  font-weight: 400;
  font-size: var(--mantine-font-size-sm);
  line-height: 18px;
  max-width: 390px;
}

.filtersRadioBody {
  justify-content: space-between;
  border-bottom: var(--mantine-color-gray-2) 1px solid;
  padding-bottom: var(--mantine-spacing-md);
}

.boldLabelFont {
  font-weight: 700;
}

.radioGroupLabelFilters {
  font-size: 18px;
  font-weight: 700;
  color: var(--mantine-color-blue-8);
  padding-bottom: var(--mantine-spacing-md);
}
