.osano-cm-widget {
  display: none;
}

.icon {
  vertical-align: middle;
}

.intercom-lightweight-app,
.intercom-lightweight-app-launcher,
.intercom-launcher,
.intercom-namespace,
.intercom-with-namespace-52k34s {
  z-index: 199 !important;
}
