@import "./_mantine.scss";
.iconClose {
  margin-top: 6px;
  color: var(--mantine-color-gray-6);
  margin-bottom: 8px;
}

.iconOpen {
  transform: rotate(180deg);
  margin-bottom: 6px;
  color: var(--mantine-color-gray-6);
  margin-top: 2px;
}

.dropdown {
  color: var(--mantine-color-blue-8);
  border-radius: 4px;
  font-weight: 700;
  padding: 0;
}

.option {
  color: var(--mantine-color-blue-8);
  line-height: 20px;
  margin-right: -4px;
  font-weight: 600;

  &:hover {
    background-color: var(--mantine-color-gray-1);
    border-radius: 0;
  }

  &[data-checked] {
    background-color: var(--mantine-color-blue-1);
    border-radius: 0;
    font-weight: 700;

    &:hover {
      background-color: var(--mantine-color-gray-1);
    }
  }
}

.input {
  font-weight: 700;
  color: var(--mantine-color-gray-6);
  border-radius: 4px;
  border-color: var(--mantine-color-gray-4);

  ::placeholder,
  ::-ms-input-placeholder {
    color: #ffffff;
  }

  &:hover {
    color: var(--mantine-color-gray-6);
    background-color: var(--mantine-color-gray-1);
  }
  &[data-expanded] {
    outline: 3px solid var(--mantine-color-blue-2);
    border: 1px solid var(--mantine-color-blue-2);
  }
}
.filterSelectedInput {
  background-color: var(--mantine-color-blue-1);
  border: 1px solid var(--mantine-color-blue-5);
  &:hover {
    background-color: var(--mantine-color-blue-2);
  }
}

.rightSection {
  pointer-events: none;
}

.wrapper {
  @media (max-width: $mantine-breakpoint-sm) {
    width: 100%;
  }

  flex: 1;
}

.financeDashboardInput {
  color: var(--mantine-color-blue-8) !important;
}
