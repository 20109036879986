@import "./_mantine.scss";
.clearButton {
  color: var(--mantine-color-blue-6);
  border: none;
  width: max-content;
  padding-inline: var(--mantine-spacing-xs);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: var(--mantine-fontSize-sm);
  }
  &:hover {
    text-decoration: underline;
    color: var(--mantine-color-blue-6);
    background-color: white;
  }
  &:disabled {
    background-color: white;
    color: var(--mantine-color-blue-6);
    opacity: 0.32;
  }
}
