@import "./_mantine.scss";
.header {
  background-color: #fff;
  padding: 2.5rem 0 1rem 2rem;
}

.integrationsHeader {
  @media (min-width: $mantine-breakpoint-sm) {
    padding: 2.5rem 0 2rem 1.5rem;
  }
}

.bookingsHeader {
  @media only screen and (max-width: $mantine-breakpoint-md) {
    padding: 0.5rem 0 1.5rem 1.5rem;
  }
}

.stickyHeader {
  @media only screen and (max-width: $mantine-breakpoint-md) {
    background-color: var(--mantine-color-white);
    padding: 1.5rem 0 0.5rem 1.5rem;
    position: sticky;
    top: 0;
    z-index: 20;
  }

  @media only screen and (max-width: $mantine-breakpoint-sm) {
    top: 70px;
    padding: 1.5rem 1.25rem 0.5rem;
  }
}

.hiddenDescription {
  @media only screen and (max-width: $mantine-breakpoint-md) {
    background-color: var(--mantine-color-white);
    padding: 0 0.75rem 1.5rem 1.5rem;
  }
}

.headerWrapper {
  display: flex;
  margin-right: 2rem;
}

.pageIntro {
  flex: 1;
  font-size: var(--mantine-font-size-sm);
  line-height: var(--mantine-lineHeight);
}

.title {
  color: var(--mantine-color-blue-8);
  margin: 0 0 0.5rem;
}

.introText {
  color: var(--mantine-color-blue-8);
  max-width: 615px;
}

.rightComponent {
  @media (max-width: 768px) {
    display: none;
  }
}

.topComponent {
  margin: 0 0 0.5rem;
}
