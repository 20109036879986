@import "./_mantine.scss";
.filterButton {
  background-color: white;
  border-radius: 4px;
  border-color: var(--mantine-color-gray-4);
  padding-left: var(--mantine-spacing-sm);
  padding-right: var(--mantine-spacing-xs);
  &:hover {
    background-color: var(--mantine-color-gray-1);
  }
  span {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.filterButtonLabel {
  font-weight: 700;
  color: var(--mantine-color-blue-8);
}

.filterSelected {
  background-color: var(--mantine-color-blue-1);
  border: 1px solid var(--mantine-color-blue-5);
  &:hover {
    background-color: var(--mantine-color-blue-2);
  }
}

.buttonIconRotate {
  &[data-position='right'] {
    transform: rotate(180deg);
    margin-bottom: 4px;
    margin-top: 0;
  }
}

.toolbar {
  display: flex;
  width: 100%;
  background-color: #fff;
  position: relative;
  padding-bottom: var(--mantine-spacing-md);
  @media (max-width: $mantine-breakpoint-md) {
    display: flex;
    justify-content: start;
  }
}

.header {
  border-bottom: 1.5px var(--mantine-color-blue-8) solid;
  padding-bottom: var(--mantine-spacing-sm);
}

.hereLink {
  color: var(--mantine-color-blue-6);
  background: white;
  padding: 0;
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 2px;

  height: auto;
  border-radius: 0;
  &:hover {
    background-color: none;
  }
}

.paymentWrapper {
  border-radius: 12px;
  border: 1px solid var(--mantine-color-gray-4);
  padding: 16px 12px;
}

.buttonWrapper {
  @media (min-width: $mantine-breakpoint-md) {
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
  }
  width: 100%;
  gap: var(--mantine-spacing-md);
  display: flex;
  flex-direction: column;
}

.totalUpcomingPayments {
  @media (max-width: $mantine-breakpoint-xs) {
    margin-top: var(--mantine-spacing-sm);
  }
}
