@import "./_mantine.scss";
.resendButton {
  color: var(--mantine-color-blue-5);
  margin-bottom: 12px;

  &:hover {
    text-decoration: underline;
  }
}

.verifyButton {
  width: 100%;
  margin: 12px 0 36px;
  background-color: var(--mantine-color-pink-6);

  &:hover {
    background-color: var(--mantine-other-pinkHover);
  }
}
