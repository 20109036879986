@import "./_mantine.scss";
.daysOfWeek {
  font-size: var(--mantine-font-size-xs);
  font-weight: 400;
  color: var(--mantine-color-gray-5);
  width: 24px;
  display: grid;
  place-items: center;
}
.dayOfWeekSelected {
  color: var(--mantine-color-blue-8);
  font-weight: 600;
}
.limeBg {
  display: flex;
  margin: 4px auto 0;
  background-color: var(--mantine-color-lime-6);
  border-radius: 24px;
  width: 14px;
  height: 3px;
}
