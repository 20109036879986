@import './variables.scss';
@import './typography';

@mixin inputAppearance {
  border-radius: 60px;
  border: none;
  background: $grey200;
  transition: 0.3s background;
  letter-spacing: -0.01em;
  color: $blue800;

  &:focus-within {
    box-shadow: 0 0 0 4px $blue200;
  }

  &::-webkit-input-placeholder {
    color: $grey500;
  }
  &::-moz-placeholder {
    color: $grey500;
  }
  &:-ms-input-placeholder {
    color: $grey500;
  }
  &:-moz-placeholder {
    color: $grey500;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    background: midnight100;
    outline: none;
  }
}

@mixin inputSize($size: 42px, $fontSize: $fontSizeDefault) {
  height: $size;
  line-height: floor($size * 0.5);
  padding: floor($size * 0.25) 0;
  font-size: $fontSize;
  font-family: $fontFamilyDefault;
}

@mixin input {
  @include inputAppearance;
  @include inputSizing;
}

@mixin labelStyle {
  @include typography;

  display: block;
  color: $blue800;
}

// Buttons
@mixin buttonAppearance {
  @include buttonReset;
  @include typographyBold($scale: 3);

  border: 1px solid $pink500;
  border-radius: 60px;
  cursor: pointer;
  transition: 0.3s opacity;
  height: 3rem;
  display: inline-flex;
  align-items: middle;
  user-select: none;
  background: $pink500;
  color: #fff;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $pink800;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.32;
  }
}

@mixin buttonReset {
  appearance: none;
  background: transparent;
  border: none;
}

@mixin buttonExpand {
  display: flex;
  width: 100%;
  text-align: center;
}

@mixin clickTravel($travelAmount: 1px) {
  transition: 0.2s transform;

  &:hover {
    transform: translateY(-($travelAmount));
  }

  &:active {
    transition: none;
    transform: none;
  }
}

@mixin title($fontSize: 24px, $lineHeight: 30px, $color: $blue800) {
  font-size: $fontSize;
  font-family: $fontFamilyDefault;
  line-height: $lineHeight;
  font-weight: 600;
  color: $color;
  letter-spacing: -0.01em;
  margin: 0 0 1rem;
}

@mixin linkAppearance {
  color: $blue600;
  text-decoration: underline;
}

@mixin linkReset {
  text-decoration: none;
}

@mixin floatingNavGradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.54) 28.09%,
    rgba(255, 255, 255, 0.8) 57.81%,
    rgba(255, 255, 255, 0.98) 89.58%
  );
}

@mixin responsiveCentered($maxWidth: $siteMaxWidth) {
  max-width: $maxWidth;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@mixin screenReaderOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@mixin centerContent {
  display: grid;
  justify-content: center;
  align-items: center;
}

@mixin topFade($zIndex: 3, $size: 10px, $color: #fff) {
  box-shadow: 0 (-$size) $size $size $color;
  z-index: $zIndex;
}

@mixin reasonListAppearance {
  color: $blue800;
  margin: 0;
  padding: 0;

  & > li {
    list-style: none;
    border-left: 3px solid $lime500;
    margin: 0;
    padding-left: 1rem;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}
