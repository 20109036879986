@import "./_mantine.scss";
.pinkButton {
  background-color: var(--mantine-color-pink-6);
  color: white;
  border: none;
  width: 100%;
  margin: 12px 0 36px;

  &:hover {
    background-color: var(--mantine-other-pinkHover);
  }
}
