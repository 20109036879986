@import "./_mantine.scss";
.drawerContent {
  height: 100%;
  border-top-left-radius: var(--mantine-radius-sm);
  box-shadow: 0px 4px 54px 0px #00000029;
  @media (max-width: $mantine-breakpoint-sm) {
    height: 97%;
    border-top-right-radius: var(--mantine-radius-sm);
  }
}

.drawerHeader {
  display: none;
}

.summaryHeader {
  padding: 40px 0 16px;

  @media (max-width: $mantine-breakpoint-sm) {
    padding: 24px 0 16px;
  }
  .activityName {
    flex-grow: 1;
  }
}

.drawerBody {
  padding: 0 24px 24px 24px;
  @media (max-width: $mantine-breakpoint-sm) {
    padding: 0 20px 24px 20px;
  }
}

.drawerCloseButton {
  background-color: #e1e1e2;
  border-radius: 50%;
  color: var(--mantine-color-gray-6);

  &:hover {
    background-color: var(--mantine-color-blue-8);
    color: white;
  }
}

.detailsIcon {
  margin-right: var(--mantine-spacing-sm);
}
.detailsValue {
  font-weight: 600;
}
.waitlist {
  border-radius: 24px;
  padding: 4px 16px;
  margin-bottom: var(--mantine-spacing-md);
  background: var(--mantine-color-blue-2);
  color: var(--mantine-color-blue-8);
  width: fit-content;
  font-size: var(--mantine-font-size-md);
  align-items: center;
  &:hover {
    background: var(--mantine-color-blue-1);
    color: var(--mantine-color-blue-8);
  }
}
.waitlistTag {
  font-size: var(--mantine-font-size-xs);
  color: var(--mantine-color-gray-6);
  font-weight: 400;
  margin-left: var(--mantine-spacing-xs);
}
.capacityButton {
  height: fit-content;
  width: 110px;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  color: white;
  &:disabled {
    color: white;
  }
}
.notificationRoot {
  padding-inline: 8px;
  background-color: var(--mantine-color-yellow-1);
  border: 1px solid #cda300;
  color: var(--mantine-color-blue-8);
  &::before {
    display: none;
  }
  display: flex;
  align-items: flex-start;
  box-shadow: none;
}
.notificationBody {
  margin-right: 0;
}
.timesVaryBadge {
  width: fit-content;
  border: 1px solid var(--mantine-color-gray-5);
  background-color: var(--mantine-color-gray-2);
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: var(--mantine-font-size-xs);
  svg {
    margin-left: 6px;
  }
}
.switchTrack {
  background-color: var(--mantine-color-gray-5);
  width: 0;
  input:checked + & {
    background-color: var(--mantine-color-lime-6);
    border: none;
  }
}
.switchLabel {
  font-weight: 600;
}

.sessionItem {
  color: var(--mantine-color-blue-8);
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  @media (min-width: $mantine-breakpoint-sm) {
    margin-top: 10px;
    border-radius: 8px;
    transition: background-color 200ms ease-out 100ms;

    &:hover {
      background-color: var(--mantine-color-blue-1);
    }
  }
  &.disabled {
    cursor: default;

    &:hover {
      background-color: white;
    }
  }
}

.menuDropdown {
  background-color: white;
  border: 2px solid var(--mantine-color-blue-2);
  border-radius: 4px;
  padding: 0;
}
.menuItem {
  color: var(--mantine-color-blue-8);
  font-weight: 600;
  padding: 6px var(--mantine-font-size-xs);
  &[data-hovered] {
    background-color: var(--mantine-color-blue-2);
    border-radius: 2px;
  }
  &:last-of-type:not(a) {
    color: var(--mantine-color-red-8);
  }
}
