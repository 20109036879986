@import "./_mantine.scss";
.calendarHeader {
  margin-bottom: var(--mantine-spacing-sm);
  button {
    font-size: 16px;
  }
}

.calendarMonth {
  font-weight: 600;
  font-size: var(--mantine-font-size-md);
  color: var(--mantine-color-blue-8);
}

.calendarPagination {
  color: var(--mantine-color-blue-8);

  svg {
    width: 24px;
    height: 24px;
  }
}

.weekday {
  color: var(--mantine-color-gray-5);
  font-size: var(--mantine-font-size-xs);
  padding-bottom: var(--mantine-spacing-xs);
  border-bottom: 1px var(--mantine-color-gray-3) solid;
}

.dayCell {
  border-top: none;

  &[data-selected] {
    font-weight: 700;
    background-color: var(--mantine-color-blue-8);
    color: var(--mantine-color-white) !important;
    border-radius: 4px;
  }

  &[data-weekend] {
    color: var(--mantine-color-gray-4);
  }
}

.upcomingDate {
  background-color: var(--mantine-color-lime-2);
  border-color: var(--mantine-color-lime-2);
  color: var(--mantine-color-blue-8);
  border-radius: 0;
  font-weight: 600;

  &[data-weekend] {
    color: var(--mantine-color-blue-8);
  }
}

.offDay {
  background: none !important;
}

.linkWrapper {
  width: 100%;
}

.goToRegisterWrapper {
  margin: 32px;

  @media (min-width: $mantine-breakpoint-xl) {
    margin: 32px 0 4px;
  }
}

.spoilerControl {
  padding-top: 20px;
  text-align: center;
  color: var(--mantine-color-blue-6);
  width: 100%;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
  text-align: center;
  padding-bottom: 4px;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

.sessionName {
  font-size: 14px;
  word-wrap: break-word;
  width: 210px;
  font-weight: 600;
  @media (max-width: $mantine-breakpoint-xl) {
    width: 150px;
  }

  @media (max-width: $mantine-breakpoint-md) {
    width: 200px;
  }
}

.activityClassName {
  font-size: 14px;
  font-weight: 600;
}

.sessionCard {
  border: 1px solid var(--mantine-color-gray-4);
  border-radius: 12px;
  padding: 8px;
  background-color: white;
}
