@import "./_mantine.scss";
.toolbar {
  background-color: #fff;
  padding: 0 var(--mantine-spacing-sm) var(--mantine-spacing-sm) var(--mantine-spacing-lg);
  justify-content: space-between;
  flex-direction: row-reverse;
  @media (min-width: $mantine-breakpoint-sm) {
    flex-direction: row;
    padding: 0 var(--mantine-spacing-lg);
    spacing: 0.25rem;
  }
}
.toolbarButton {
  background-color: var(--mantine-color-blue-1);
  color: var(--mantine-color-blue-8);
  &:hover {
    background-color: var(--mantine-color-blue-1);
  }
}
.active {
  background-color: var(--mantine-color-blue-5);
  color: white;
  &:hover {
    background-color: var(--mantine-color-blue-6);
    color: white;
  }
}
.paginationButtons {
  [data-active] {
    background-color: var(--mantine-color-blue-6);
  }
}
.paginationWrapper {
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-sm) {
    padding-bottom: 75px;
  }
}
.settingsButton {
  background-color: var(--mantine-color-blue-5);
  border: none;
  display: inline-block;
  height: 36px;
  border-radius: 26px;
  width: 41px;
  min-width: 36px;
  text-align: center;
  color: white;
  &:hover {
    background-color: var(--mantine-color-blue-6);
  }
  svg {
    fill: white;
  }
  @media (min-width: $mantine-breakpoint-sm) {
    display: none;
  }
}
