@import "./_mantine.scss";
.dot {
  height: 1em;
  width: 1em;
  border-radius: 50%;
}
.borderedDot {
  height: 24px;
  width: 24px;
}

.tooltip {
  background-color: var(--mantine-color-blue-8);
  border-radius: 3px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
}
