@import "./_mantine.scss";
.badge {
  background: #cff0e7;
  border-radius: 4px;
  padding: 2px 4px;
  min-width: fit-content;
}

.badgeLabel {
  text-transform: none;
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  font-size: var(--mantine-font-size-sm);
}
