@import "./_mantine.scss";
@keyframes fadeInAnimation {
  from {
    background-color: var(--mantine-color-lime-1);
  }
  to {
    background-color: var(--mantine-color-gray-1);
  }
}

.card {
  border-left: 8px solid var(--mantine-color-blue-5);
  position: relative;
}
.new {
  background-color: var(--mantine-color-lime-1);
  animation: fadeInAnimation 500ms ease-in 2;
}
.unpublished {
  opacity: 0.5;
}
.title {
  display: block;
  max-width: 90%;
  line-height: 24px;
  font-weight: 600;
  font-size: var(--mantine-font-size-lg);
  color: var(--mantine-color-blue-5);
}
.subsTypeIcon {
  margin-left: 1px;
  margin-right: -2px;
}
.activityTypeIcon {
  margin-left: 2px;
  margin-right: 4px;
}
.detailsIcon {
  margin-right: var(--mantine-spacing-sm);
}
.collapseButton {
  position: absolute;
  top: 15px;
  right: 10px;
}
.detailsValue {
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-blue-8);
  font-weight: 600;
}
.open {
  transform: rotate(180deg);
}
.stackContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.iconButtons {
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
}
.deleteButton {
  color: var(--mantine-color-red-8);
  padding-right: var(--mantine-spacing-xs);
}
.cloneButton {
  svg {
    color: var(--mantine-color-blue-5);
  }
  color: var(--mantine-color-blue-8);
  padding-left: var(--mantine-spacing-xs);
}
.switch {
  color: var(--mantine-color-gray-6);
}
.switchTrack {
  background-color: var(--mantine-color-gray-5);
  input:checked + & {
    background-color: var(--mantine-color-lime-6);
    border: none;
  }
}
.rotate90deg {
  transform: rotate(90deg);
}
.waitlist {
  border-radius: 2px;
  padding: 4px;
  background: var(--mantine-color-blue-2);
  color: var(--mantine-color-blue-8);
  font-size: 12px;
}
