@import "./_mantine.scss";
.segmentedControlRoot {
  width: 191px;
}

.controlIndicator {
  background-color: var(--mantine-color-blue-8) !important;
  height: 33px;
  @media (max-width: $mantine-breakpoint-sm) {
    width: 50%;
  }
}

.pseudoTabButtonLabel {
  padding-inline: var(--mantine-spacing-lg);
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;
  color: var(--mantine-color-blue-8);
  &[data-active] {
    color: white;

    &:hover {
      color: white;
    }
  }
  @media (max-width: $mantine-breakpoint-sm) {
    padding-inline: 0;
  }
}

.drawerContent {
  border-top-left-radius: var(--mantine-radius-sm);
  border-top-right-radius: var(--mantine-radius-sm);
}

.header {
  h2 {
    font-weight: 700;
    font-size: var(--mantine-font-size-lg);
  }
}

.drawerBody {
  margin-top: var(--mantine-spacing-lg);
}
