@import "./_mantine.scss";
.backButton {
  position: fixed;
  top: 18px;
  left: 20px;
  z-index: 99999;
  padding: 0;

  @media (min-width: $mantine-breakpoint-sm) {
    left: 16px;
  }
}

.title {
  margin-top: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
  @media (min-width: $mantine-breakpoint-sm) {
    margin-bottom: 12px;
  }
}

.copy {
  font-size: var(--mantine-font-size-sm);
  line-height: 18px;
  margin-bottom: 40px;
  @media (min-width: $mantine-breakpoint-sm) {
    margin-bottom: 32px;
  }
}

.wrapper {
  @media (min-width: $mantine-breakpoint-sm) {
    width: 525px;
    margin: 0 auto 32px;
  }
}

.warningWrapper {
  background-color: var(--mantine-color-blue-2);
  padding: 8px 12px;
  margin-block: 32px;
  border-radius: 4px;
  svg {
    margin-top: -2px;
  }
}
.warningCopy {
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
  line-height: 16px;
  color: var(--mantine-color-blue-8);
  margin-left: 8px;
}
