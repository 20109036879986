@import "./_mantine.scss";
@import '../../../../../shared/ui/sass/variables.scss';
@import '../../../../../shared/ui/sass/mixins.scss';
@import '../../../../../shared/ui/sass/typography.scss';

.container {
  display: grid;
  padding: 2rem;
  height: 100vh;
  height: -webkit-fill-available;

  h1,
  .title {
    @include typographyBold($scale: 7);

    margin: 0 0 1rem;

    &:first-child {
      margin-top: 180px;
    }
  }

  a {
    color: $blue600;
  }

  p {
    @include typography;

    margin: 0 0 1.5rem;
  }
}

.content {
  grid-column: 1;
  color: $blue800;
}

.contentWrapper {
  max-width: 448px;
  margin: 113px auto 0;
}

.backButton {
  @include buttonReset;
  @include typographySemibold;

  color: $blue600;
  display: flex;
  align-self: center;
  cursor: pointer;
  transition: 0.2s transform;
  padding: 0;
  margin: 0 0 3rem;

  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    transition: none;
    transform: none;
  }
}

.chevronIcon {
  margin-right: 0.5rem;
  align-self: center;
}

.illustration {
  grid-column: 2;
  display: flex;
  padding-left: 1.5rem;
  width: 100%;
  max-width: 580px;
  border-radius: 40px;
  max-height: calc(100% - 2rem);
  margin-left: auto;
  align-self: center;
  @media only screen and (max-width: 850px) {
    display: none;
  }
}

.backLink {
  position: absolute;
  top: 0;
  left: 0;
  margin: 1rem 0 0 1rem;
  color: dodgerblue;
}
