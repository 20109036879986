@import "./_mantine.scss";
.checkboxInput {
  border-radius: 2px;
  border: 2px solid var(--mantine-color-blue-5);
  &:checked {
    background-color: var(--mantine-color-blue-5);
    border-color: var(--mantine-color-blue-5);
  }
}
.disabledInput {
  &:disabled {
    border: 2px solid #dee2e6;
  }
  &:checked {
    &:disabled {
      background-color: var(--mantine-color-gray-5);
      border: none;
    }
  }
}
.checkboxIcon {
  color: var(--mantine-color-lime-5) !important;
}
.disabledIcon {
  color: var(--mantine-color-gray-2) !important;
}
.checkboxIconIndeterminate {
  color: var(--mantine-white) !important;
}
.checkboxLabel {
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
}
.disabledLabel {
  color: var(--mantine-color-gray-6) !important;
  cursor: not-allowed !important;
  font-weight: 700;
}
.checkboxInputIndeterminate {
  background-color: #a8abbd;
  border: 2px solid #a8abbd;
}

.fullWidth {
  width: 100%;
  justify-content: space-between;
  margin-bottom: var(--mantine-spacing-xs);
}

.checkboxLabelOriginal {
  font-weight: 400;
  &[data-disabled='true'] {
    color: #7f839b !important;
  }
}

.checkboxLabelMediumFont {
  font-weight: 600;
}

.selectedCheckboxInput {
  &:disabled {
    background-color: #a8abbd;
    border: 2px solid #a8abbd;
  }
}
