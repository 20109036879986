@import "./_mantine.scss";
.wrapper {
  @media (min-width: $mantine-breakpoint-sm) {
    width: 550px;
    margin: 32px auto 0;
  }
}

.checkCircle > svg > path {
  color: var(--mantine-color-blue-8);
}

.title {
  font-size: var(--mantine-font-size-xl);
  font-weight: 700;
  line-height: 24px;
  color: var(--mantine-color-blue-6);
}

.warning {
  background-color: var(--mantine-color-yellow-1);
  padding: 8px;
  border: 1px solid var(--mantine-color-yellow-6);
  display: flex;

  p {
    font-size: var(--mantine-font-size-xs);
    line-height: 16px;
    color: var(--mantine-color-blue-8);
    font-weight: 600;

    span {
      font-weight: 700;
      font-style: italic;
    }
  }
}
