@import "./_mantine.scss";
.boxStyling {
  background-color: #f1f1f2;
  padding: 0;
  min-height: 100vh;
  max-width: 100vw;
  @media (min-width: $mantine-breakpoint-sm) {
    max-width: calc(100vw - 224px);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
  }
}

// required for summary box on /discount-codes/create-code to use position: sticky
.boxStyling.overflowUnset {
  @media (min-width: $mantine-breakpoint-sm) {
    overflow-x: unset;
    overflow-y: unset;
  }
}
