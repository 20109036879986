@import "./_mantine.scss";
.sidebar {
  background-color: var(--mantine-other-midnight);
  height: 100dvh;
  position: relative;
  top: 0px;
}

.drawerSidebar {
  background-color: var(--mantine-other-midnight);
  height: 100dvh;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.sidebarHeader {
  background-color: var(--mantine-other-midnight);
  margin-bottom: -12px;
  padding: 12px 12px 0px 0px;
  button {
    background-color: #fff;
  }
}

.pebbleLogo {
  align-self: start;
  min-height: 72px;
  padding-left: 4px;
}

.link {
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;
  &:hover {
    background-color: var(--mantine-other-midnight);
    border-color: #fff;
    color: #fff;
  }
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.logoutLink {
  color: #fff;
  text-decoration: none;
  margin-top: 16px;
  font-size: var(--mantine-font-size-sm);
  &:hover {
    text-decoration: underline;
  }
}

.linkInner {
  justify-content: flex-start;
}

.linkLabel {
  overflow: visible;
}

.activeLink {
  background-color: var(--mantine-color-blue-5);
  border-radius: 5px;
  &:hover {
    border-color: #fff;
    background-color: var(--mantine-color-blue-5);
  }
}

.disabledLink {
  background-color: var(--mantine-other-midnight);
  color: var(--mantine-color-gray-5) !important;
  &:hover {
    background-color: var(--mantine-other-midnight);
    border-color: transparent;
    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  pointer-events: none;
}

.linkIco {
  margin-right: 0.5rem;
  align-self: center;
}

.boxShadow {
  box-shadow: -10px -60px 30px -10px rgb(11, 23, 78, 0.8);
  z-index: 1000;
}

.helpArea {
  margin-top: auto;
  text-align: center;
  padding: 12px 1.5rem 1rem;
  color: var(--mantine-color-blue-2);

  strong {
    font-weight: 700;
    font-size: var(--mantine-font-size-sm);
    line-height: 18px;
    display: block;
    color: white;
  }

  p {
    margin: 0 0 1.5rem;
  }

  @media (max-width: $mantine-breakpoint-sm) {
    padding: 0;
  }
}

.supportLink {
  white-space: pre;
  color: #fff;
  border-color: #fff;
  &:hover {
    color: #fff;
  }
}
.supplierSelectInput {
  background-color: var(--mantine-other-midnight);
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: var(--mantine-font-size-sm);
}
.supplierSelectRight {
  svg {
    color: white !important;
  }
}

.footerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linkContainer {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--mantine-color-gray-5) var(--mantine-other-midnight);

  &::-webkit-scrollbar-thumb {
    background: var(--mantine-color-gray-5);
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: var(--mantine-other-midnight);
  }
}

.drawerSidebarCloseBtn {
  border-radius: 50px;
  svg {
    color: var(--mantine-color-blue-8);
  }
}
