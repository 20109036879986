@import "./_mantine.scss";
.sessionName {
  word-wrap: break-word;
  white-space: pre;
  text-overflow: ellipsis;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 5px;
  scrollbar-width: none;
}

.iconWrapper {
  border: 1px solid;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.classInfo {
  flex: 3 0 40px;
  overflow: hidden;
}

.capacityBadge {
  width: 90px;
  flex: 1 0 0;
}

.updateCapacityBtn {
  display: flex;
  justify-content: flex-end;
  flex: 1 0 0;
}

.spoilerControl {
  margin-top: var(--mantine-spacing-sm);
  color: var(--mantine-color-blue-5);
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}

.boxWrapper {
  border: 1px solid var(--mantine-color-gray-2);
  padding: var(--mantine-spacing-lg) var(--mantine-spacing-lg) var(--mantine-spacing-xl);
  border-radius: 6px;
  margin: var(--mantine-spacing-sm) 0;

  @media (max-width: $mantine-breakpoint-sm) {
    padding: var(--mantine-spacing-sm) var(--mantine-spacing-sm) var(--mantine-spacing-lg);
  }
}
