@import "./_mantine.scss";
@keyframes fadeInAnimation {
  from {
    background-color: var(--mantine-color-lime-1);
  }
  to {
    background-color: var(--mantine-color-gray-1);
  }
}

.table {
  thead tr th {
    font-size: var(--mantine-font-size-xs);
    color: var(--mantine-color-blue-8);
    font-weight: 400;
    border-bottom: none;
    border-top: 1px solid var(--mantine-color-gray-3);
    padding: var(--mantine-spacing-xs) 0;
    text-align: left;
  }
  tbody tr td {
    border-top: none;
    font-size: var(--mantine-font-size-sm);
    font-weight: 700;
    color: var(--mantine-color-blue-8);
    border-bottom: none;
    background-color: var(--mantine-color-gray-1);
    padding: var(--mantine-spacing-md) var(--mantine-spacing-xs);
  }

  tbody tr.new td {
    background-color: var(--mantine-color-lime-1);
    animation: fadeInAnimation 500ms ease-in 2;
  }

  tbody tr td.tdNoResult {
    background-color: white;
  }

  tbody tr {
    border-bottom: 8px solid white;
  }
}

.dateDivider {
  font-weight: 600;
  padding-left: var(--mantine-spacing-xs);
  padding-right: var(--mantine-spacing-xs);
}
.noResult {
  height: 50vh;
}
