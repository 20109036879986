@import "./_mantine.scss";
.table {
  thead tr th {
    font-size: var(--mantine-font-size-xs);
    color: var(--mantine-color-gray-5);
    font-weight: 400;
    padding: var(--mantine-spacing-xs) 0 0;
    text-align: center;
    &:first-of-type {
      text-align: start;
    }
  }
  tbody tr {
    border-bottom: 1px solid var(--mantine-color-gray-4);
    &:last-of-type {
      border-bottom: none;
    }
  }
  tbody tr td {
    font-size: var(--mantine-font-size-sm);
    font-weight: 600;
    color: var(--mantine-color-blue-8);
    padding: 8px 0;
    word-break: break-word;
    text-align: center;

    &:first-of-type {
      text-align: start;
      width: 75%;
      @media (max-width: $mantine-breakpoint-sm) {
        width: 60%;
      }
    }

    @media (max-width: $mantine-breakpoint-sm) {
      font-size: var(--mantine-font-size-xs);
    }
  }
  tbody tr.opacity td:not(:last-of-type) {
    opacity: 0.32;
  }
  tbody tr.verticalAlignment td {
    vertical-align: top;
    &:not(:last-of-type) {
      padding-top: 13px;
    }
    @media (max-width: $mantine-breakpoint-sm) {
      &:nth-of-type(2) {
        padding-top: 14px;
      }
    }
  }
}

.noResult {
  height: 15vh;
}

.cardWrapper {
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-sm) 0;
  margin-block: var(--mantine-spacing-xl);
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px #15254e1f;
}

.undoBtn {
  height: 28px;
  width: fit-content;
  padding: 0;
  font-size: var(--mantine-font-size-xs);
  font-weight: 700;
  line-height: 16px;
}

.customerName {
  font-size: var(--mantine-font-size-sm);
  line-height: 18px;
  font-weight: 600;
  text-decoration: underline;
}
.selectedCustomerName {
  color: var(--mantine-color-blue-6);
}

.sortHeader {
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;
  color: var(--mantine-color-blue-6);

  svg {
    margin-left: var(--mantine-spacing-xs);
  }

  &:hover {
    cursor: pointer;
  }
}
