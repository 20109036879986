@import "./_mantine.scss";
@import '../../../../../shared/ui/sass/variables';
@import '../../../../../shared/ui/sass/typography';

.container {
  background: $blue500 url('/background.jpg') no-repeat;
  background-size: cover;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: center;
  padding: 0 2rem;
}

.dialog {
  max-width: 640px;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  text-align: center;
  padding: 3rem 1.5rem;

  h1 {
    @include typographyBold($scale: 6);

    color: $blue500;
    margin: 1.5rem 0 1rem;
  }

  p {
    @include typography;

    color: $blue800;
    max-width: 461px;
    margin: 0 auto 2rem;
  }
}

.pebbleMedal {
  text-align: center;
}

.actions {
  max-width: 280px;
  margin: 0 auto;
}

.pebbleLogo {
  position: fixed;
  top: 0;
  left: 0;
  margin: 1rem;
}
