@import "./_mantine.scss";
.label {
  font-weight: 600;
}
.labelActive {
  font-weight: 700;
}
.controlLabel {
  font-weight: 700;
  color: var(--mantine-color-gray-5);
  padding: 8px 24px;
  &[data-active] {
    color: white;
  }
}
.control {
  border-radius: var(--mantine-radius-lg);
  background-color: var(--mantine-color-gray-1) !important;
  border: 2px solid var(--mantine-color-gray-1) !important;

  &[data-active] {
    background-color: var(--mantine-color-blue-6) !important;
  }
}
