@import "./_mantine.scss";
.wrapper {
  @media (min-width: $mantine-breakpoint-sm) {
    width: 525px;
    margin: 0 auto;
  }
}

.backButton {
  position: fixed;
  top: 18px;
  left: 20px;
  z-index: 99999;
  padding: 0;

  @media (min-width: $mantine-breakpoint-sm) {
    left: 16px;
  }
}

.title {
  margin-top: 16px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
  @media (min-width: $mantine-breakpoint-sm) {
    margin-bottom: 12px;
  }
}

.groupTitle {
  font-weight: 700;
  font-size: var(--mantine-font-size-md);
  line-height: 22px;
}

.copy {
  p {
    font-size: var(--mantine-font-size-sm);
    line-height: 22px;
  }
  margin-bottom: 32px;
}

.messageBoxInput {
  background-color: var(--mantine-color-gray-2);
  padding: 10px 16px;
  height: 100px;
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-md);
  font-weight: 600;
  line-height: 22px;

  &::placeholder {
    color: var(--mantine-color-gray-5);
  }
}

.groupBox {
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0px 4px 24px 0px #0b174e1a;
}

.infoBox {
  background-color: var(--mantine-color-blue-1);
  padding: 8px;
  display: flex;

  p {
    font-size: var(--mantine-font-size-xs);
    line-height: 16px;
    color: var(--mantine-color-blue-8);
    font-weight: 600;
  }

  svg {
    color: var(--mantine-color-blue-8);
  }
}

.followUpDates {
  gap: 16px;
}

.dateInput {
  background-color: var(--mantine-color-gray-2);
  border-radius: 4px;
  padding: 10px 16px;
  border: none;
}

.dateInputLabel {
  color: var(--mantine-color-blue-8);
  margin-bottom: 12px;
  font-size: var(--mantine-font-size-md);
  font-weight: 400;
  line-height: 22px;
}

.dateInputSection {
  color: var(--mantine-color-blue-8);
}
