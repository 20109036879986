// Layout
$siteMaxWidth: 600px;
$stickyNavHeight: 90px;
$dayPickerCalendarWidth: 322px;
$sidebarWidth: 224px;

// Layout: zIndexes
$zIndexStickyNav: 3;
$zIndexModalBackdrop: 4;
$zIndexModal: 5;
$zIndexDialog: 6;

// Colors: Primary
$pink800: #ae005e;
$pink600: #e5489d;
$pink500: #fb54ae;
$pink200: #fed0e9;
$pink100: #ffeff8;

$lime800: #7ecb01;
$lime600: #9de551;
$lime500: #c6f991;
$lime200: #ecfdda;
$lime100: #f7ffef;

$blue800: #0e206f;
$blue600: #2d5fe8;
$blue500: #4378ff;
$blue200: #d5e1ff;
$blue100: #f0f4ff;

// Colors: Secondary
$green800: #0f634a;
$green600: #148867;
$green500: #37ad8b;
$green200: #cff0e7;
$green100: #e7f7f3;

$yellow800: #cda300;
$yellow600: #efc830;
$yellow500: #f7d347;
$yellow200: #fcf0c2;
$yellow100: #fefaeb;

$orange800: #b85e00;
$orange600: #f8840d;
$orange500: #f99834;
$orange200: #fdddbb;
$orange100: #fef4e8;

$red800: #911a00;
$red600: #d93e1c;
$red500: #e74522;
$red200: #ffcbbf;
$red100: #fceae6;

$cyan800: #006e8b;
$cyan600: #29aed1;
$cyan500: #3fbbdc;
$cyan200: #d4f0f7;
$cyan100: #eaf7fb;

$midnight900: #0b174e;
$midnight800: #243059;
$midnight600: #4f5b84;
$midnight500: #7b86aa;
$midnight300: #b7bfd7;
$midnight200: #e2e7f5;
$midnight100: #f3f6ff;

$dark800: #243059;
$dark600: #4f5b84;
$dark500: #7b86aa;
$dark300: #b7bfd7;
$dark200: #e2e7f5;
$dark100: #f3f6ff;

$grey800: #1f2744;
$grey600: #4c5471;
$grey500: #7f839b;
$grey200: #f1f1f2;
$grey100: #f6f6f6;

// Black
$black300: #acacac;
$black200: #dedede;
