@import "./_mantine.scss";
.tabsList {
  display: none;
}

.drawerContent {
  height: auto;
  max-height: 85vh;
  border-top-right-radius: var(--mantine-radius-sm);
  border-top-left-radius: var(--mantine-radius-sm);
}

.drawerBody {
  padding: 0 24px 20px;
}

.drawerHeaderHidden {
  display: none;
}

.closeButton {
  position: absolute;
  top: 24px;
  right: 20px;
  color: var(--mantine-color-blue-8);
  z-index: 9999;
  cursor: pointer;
}

.modalHeader {
  position: sticky;
  background-color: white;
  padding: 0;
  height: 16px;
}

.modalBody {
  padding: 0;
  overflow: hidden;
}

.modalContent {
  overflow: hidden;
  width: 550px;
}

.modalInnerWrapper {
  position: relative;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
}

.title {
  font-weight: 700;
  font-size: var(--mantine-font-size-xl);
  line-height: 36px;
  color: var(--mantine-color-blue-6);
  padding-inline: var(--mantine-spacing-sm);
  margin-bottom: var(--mantine-spacing-md);
  text-align: center;
}

.hideButton {
  display: none;
}
