@import "./_mantine.scss";
.mainColumn {
  order: 1;
  @media (max-width: $mantine-breakpoint-md) {
    order: 2;
  }
}

.sideColumn {
  order: 2;
  @media (max-width: $mantine-breakpoint-md) {
    order: 1;
  }
}

.card {
  height: 100%;
  position: relative;
}

.searchIcon {
  color: var(--mantine-color-blue-5);
}

.select {
  background-color: var(--mantine-white);
}

.container {
  @media (min-width: 1350px) {
    display: flex;
    width: 1200px;
    margin: 0 auto;
  }
  padding: var(--mantine-spacing-xl) var(--mantine-spacing-xl) 0 var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-md) {
    padding: 20px 20px 0 20px;
  }
}

.gridCol {
  @media (max-width: $mantine-breakpoint-md) {
    padding: 10px;
  }
}

.setUpLink {
  color: var(--mantine-color-blue-6);
}
