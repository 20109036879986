@import "./_mantine.scss";
.input {
  background-color: var(--mantine-color-gray-2);
  border: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: var(--mantine-font-size-md);
}

.dropdown {
  color: #ffffff;
  background-color: var(--mantine-color-pink-5);
  border-radius: 4px;
  font-weight: 700;
  padding: 0;
}

.item {
  color: #ffffff;
  padding-block: var(--mantine-spacing-sm);
  color: var(--mantine-color-blue-8);

  &[data-checked] {
    background-color: var(--mantine-color-gray-2);
    color: var(--mantine-color-blue-8);
    font-weight: bold;
  }
  
  &:hover {
    color: var(--mantine-color-blue-8);
    background-color: var(--mantine-color-gray-1);
  }
}

.label {
  display: flex;
  gap: 1px;
  color: white;
  color: var(--mantine-color-blue-8);
}
