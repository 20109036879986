@import "./_mantine.scss";
@keyframes fadeInAnimation {
  from {
    background-color: var(--mantine-color-lime-1);
  }
  to {
    background-color: var(--mantine-color-gray-1);
  }
}

.card {
  background-color: var(--mantine-color-gray-1);
  border-radius: 4px;
  position: relative;
}
.new {
  background-color: var(--mantine-color-lime-1);
  animation: fadeInAnimation 500ms ease-in 2;
}
.title {
  // TO DO check sizes
  // ...theme.headings.sizes.h5,
  font-size: var(--mantine-font-size-md);
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  margin-top: 0;
  margin-bottom: var(--mantine-spacing-xs);
}
.dateDivider {
  font-weight: 600;
  padding-left: var(--mantine-spacing-xs);
  padding-right: var(--mantine-spacing-xs);
}
.detailsLabel {
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  min-width: 60px;
  margin-right: var(--mantine-spacing-md);
}
.primaryButton {
  background-color: var(--mantine-color-blue-8);
  font-weight: 700;
  &:hover {
    background-color: var(--mantine-other-blueHover);
  }
}
.secondaryButton {
  background-color: var(--mantine-color-red-1);
  color: var(--mantine-color-red-6);
  font-weight: 700;
}
