@import "./_mantine.scss";
.cardWrapper {
  overflow: revert;
  padding: 20px;
}

.cardWrapperFullHeight {
  height: 100%;
}

.settingsIcon {
  background-color: var(--mantine-color-blue-2);
  height: 35px;
  width: 35px;
  padding: 4px;
  border: 1px solid var(--mantine-color-blue-8);
  border-radius: 50%;
}
