@import "./_mantine.scss";
.backButton {
  position: fixed;
  top: 18px;
  left: 20px;
  z-index: 99999;
  padding: 0;

  @media (min-width: $mantine-breakpoint-sm) {
    left: 16px;
  }
}

.title {
  margin-top: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
  @media (min-width: $mantine-breakpoint-sm) {
    margin-bottom: 12px;
  }
}

.subheader {
  font-weight: 700;
  font-size: 18px;
}

.copy {
  font-size: var(--mantine-font-size-sm);
  line-height: 18px;
  margin-bottom: 40px;
  @media (min-width: $mantine-breakpoint-sm) {
    margin-bottom: 32px;
  }
}

.crossIcon {
  position: absolute;
  top: 12px;
  right: -30px;
  border-radius: var(--mantine-radius-lg);
  color: white;
  background-color: white;
}

.addCustomerButton {
  background-color: white;
  color: var(--mantine-color-pink-5);
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 24px;
  padding: 0;
}

.primaryButton {
  background-color: var(--mantine-color-pink-6);
  border-radius: var(--mantine-radius-lg);
  color: white;
  font-weight: 700;
  padding: 8px 32px;
  font-size: var(--mantine-font-size-sm);
  height: auto;
  text-align: center;
  width: 100%;
  span {
    overflow: visible;
  }

  &:hover {
    background-color: var(--mantine-other-pinkHover);
  }

  @media (min-width: $mantine-breakpoint-sm) {
    margin-bottom: 40px;
  }
}

.textInput {
  margin: 20px 0;
  font-weight: 600;
  width: 92%;

  label {
    font-size: 16px;
  }

  input {
    font-size: 16px;
  }

  @media (min-width: $mantine-breakpoint-sm) {
    width: 95%;
  }
}

.wrapper {
  @media (min-width: $mantine-breakpoint-sm) {
    width: 525px;
    margin: 0 auto;
  }
}
