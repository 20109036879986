@import "./_mantine.scss";
.subheader {
  font-size: var(--mantine-font-size-md);
}

.textInput {
  margin: 20px 0;
  font-weight: 600;
  input {
    font-size: 16px;
  }
}

.inputWithDeleteBtn {
  width: 90%;
  @media (min-width: $mantine-breakpoint-sm) {
    width: 95%;
  }
}

.addCustomerButton {
  background-color: white;
  color: var(--mantine-color-pink-5);
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 24px;
  padding: 0 8px 0 0;
  &:hover {
    background-color: var(--mantine-other-pinkHover);
    color: white;
  }
}

.crossIcon {
  position: absolute;
  top: 12px;
  right: -35px;
  border-radius: var(--mantine-radius-lg);
  color: white;
  background-color: white;
}
