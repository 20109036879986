@import "./_mantine.scss";
.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  padding: 22px 16px;
  margin: 0;
  overflow: scroll;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.03), 0px 0px 33.4221px rgba(0, 0, 0, 0.022149),
    0px 0px 17.869px rgba(0, 0, 0, 0.017202), 0px 0px 10.0172px rgba(0, 0, 0, 0.0138973),
    0px 0px 5.32008px rgba(0, 0, 0, 0.0113405), 0px 0px 2.21381px rgba(0, 0, 0, 0.00839204);

  &:hover {
    cursor: pointer;
  }

  @media (min-width: $mantine-breakpoint-sm) {
    width: 350px;
  }
}

.cardLeftSection {
  font-size: var(--mantine-font-size-lg);
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  line-height: 24.5px;
}

.cardRightSection {
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;
  color: #888888;
}

.update {
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;
  color: var(--mantine-color-blue-6);

  &:hover {
    color: var(--mantine-color-blue-5);
  }
}

.scheduleFilterValue {
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;
  line-height: 22px;
  color: #888888;
}

.cardWithFiltersApplied {
  align-items: flex-start;
}

.additionalPadding {
  height: 70px;
}
