@import "./_mantine.scss";
.label {
  color: var(--mantine-color-blue-8);
  padding-bottom: var(--mantine-spacing-xs);
}

.disabledLabel {
  color: var(--mantine-color-gray-5);
}

.labelSizeMd {
  font-size: var(--mantine-font-size-md);
  margin-bottom: 4px;
}

.inputWrapper {
  flex: 1;
}

.input {
  background-color: var(--mantine-color-gray-2);
  border: none;
  border-radius: 4px;

  &::placeholder {
    font-weight: 600;
  }
}

.whiteBgInput {
  background-color: white;
}

.disabledInput {
  background-color: var(--mantine-color-gray-2);
  border: none;
  color: var(--mantine-color-gray-6);
  opacity: 1;
}

.inputError {
  background-color: var(--mantine-color-gray-2);
  color: #d93e1c;
  border-radius: 4px;
  border: none;
}

.inputRightSection {
  display: flex !important;
}
