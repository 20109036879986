@import "./_mantine.scss";
.modal {
  padding: var(--mantine-spacing-lg);
  text-align: center;
}

.modalTitle {
  padding: var(--mantine-spacing-lg) 60px;
  color: var(--mantine-color-blue-5);
  @media (max-width: $mantine-breakpoint-sm) {
    padding: var(--mantine-spacing-lg);
  }
}

.header {
  display: none;
}
