@import "./_mantine.scss";
.box {
  background-color: var(--mantine-color-blue-2);
  border-radius: var(--mantine-radius-xs);
  padding: var(--mantine-spacing-sm);
  margin: var(--mantine-spacing-sm) 0;
  color: var(--mantine-color-blue-8);
}
.icon {
  min-width: 32px;
}