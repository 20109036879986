@import "./_mantine.scss";
@keyframes fadeInAnimation {
  from {
    background-color: var(--mantine-color-lime-1);
  }
  to {
    background-color: var(--mantine-color-gray-1);
  }
}
.activityName {
  font-weight: 700;
  font-size: var(--mantine-font-size-sm);
  &:hover {
    cursor: pointer;
  }
  max-width: 90%;
}
.switchRoot {
  margin-left: var(--mantine-spacing-md);
}

.switchTrack {
  background-color: var(--mantine-color-gray-5);
  border: none;
  input:checked + & {
    background-color: var(--mantine-color-lime-6);
  }
}
.menuDropdown {
  background-color: white;
  border: 2px solid var(--mantine-color-blue-2);
  border-radius: 4px;
  padding: 0;
}
.menuItem {
  color: var(--mantine-color-blue-8);
  font-weight: 600;
  padding: 6px var(--mantine-font-size-xs);
  &[data-hovered] {
    background-color: var(--mantine-color-blue-2);
    border-radius: 2px;
  }
  &:last-of-type:not(a) {
    color: var(--mantine-color-red-8);
  }
}
.tooltip {
  background-color: var(--mantine-color-blue-8);
  border-radius: 3px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
}
.menuTarget {
  width: 36px;
  height: 36px;
  &:hover {
    border-radius: 50%;
    width: 36px;
    height: 36px;
  }
}
.unpublished {
  opacity: 0.5;
}
.opacity {
  opacity: 0.32;
  pointer-events: none;
}
.new td {
  background-color: var(--mantine-color-lime-1) !important;
  animation: fadeInAnimation 500ms ease-in 2;
}
.kebabIcon {
  padding: var(--mantine-spacing-xs);
  max-width: fit-content;
  &:hover {
    background-color: var(--mantine-color-gray-3);
    border-radius: 50%;
  }
}
.waitlist {
  border-radius: 2px;
  padding: 5px 4px;
  background: var(--mantine-color-blue-2);
  color: var(--mantine-color-blue-8);
}
.switchBox {
  padding: 12px;
  &:hover {
    background-color: var(--mantine-color-gray-2);
    border-radius: 24px;
    cursor: pointer;
  }
}
.narrow {
  width: 60px;
  text-align: center;
}
