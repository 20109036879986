@import "./_mantine.scss";
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.4);
  z-index: 1;
}

.floating {
  position: absolute;
  top: 100%;
  left: 46px;
  z-index: 5;
}

.homePageReport {
  position: absolute;
  left: 0;
  bottom: 100%;
  z-index: 5;
}

.drawer {
  border-top-right-radius: var(--mantine-radius-xl);
  border-top-left-radius: var(--mantine-radius-xl);
}

.drawerHeader {
  padding-top: var(--mantine-spacing-lg);
  padding-right: var(--mantine-spacing-lg);
}

.drawerCloseButton {
  .svg {
    width: 24;
    height: 24;
    stroke-width: 2;
    color: var(--mantine-color-blue-8);
  }
}

.buttons {
  background-color: var(--mantine-color-white);
  border-radius: var(--mantine-radius-xl);
  margin-bottom: var(--mantine-spacing-md);
  padding: var(--mantine-spacing-sm);
  gap: var(--mantine-spacing-lg);
}

.button {
  font-weight: 600;
  background-color: var(--mantine-color-blue-1);
  color: var(--mantine-color-blue-8);

  &:hover {
    background-color: var(--mantine-color-blue-2);
    color: var(--mantine-color-blue-8);
  }
}

.activeButton {
  color: var(--mantine-color-white);
  background-color: var(--mantine-color-blue-5);

  &:hover {
    background-color: var(--mantine-color-blue-6);
    color: var(--mantine-color-white);
  }
}

.calendarWrapper {
  border-radius: var(--mantine-radius-xs);
  background-color: var(--mantine-color-white);
  text-align: center;
  padding: var(--mantine-spacing-sm);
}

.base {
  margin: 0px auto;
}

.calendarHeader {
  color: var(--mantine-color-blue-8);
  font-weight: 700;
}

.calendarControl {
  color: var(--mantine-color-blue-8);

  svg {
    width: 24;
    height: 24;
  }
}

.dayCell {
  &[data-today] {
    font-weight: 700;
  }

  &[data-weekend] {
    color: var(--mantine-color-blue-8);
  }

  &[data-in-range] {
    background-color: #445690 !important;
    color: #fff !important;
  }

  &[data-first-in-range] {
    background-color: #0e206f !important;
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
    color: #fff;
  }

  &[data-last-in-range] {
    background-color: var(--mantine-color-blue-8) !important;
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}

.viewButton {
  span {
    color: white;
  }
  background-color: var(--mantine-color-pink-6);
  max-width: 174px;
  margin: 12px auto;
  font-weight: 600;
  border-radius: var(--mantine-radius-lg);

  @media (max-width: $mantine-breakpoint-sm) {
    margin-top: var(--mantine-spacing-lg);
    max-width: 100%;
  }

  &[disabled] {
    background-color: var(--mantine-color-gray-2);
    span {
      color: var(--mantine-color-gray-6);
    }

    &:hover {
      background-color: var(--mantine-color-gray-2);
    }
  }

  &:hover {
    background-color: var(--mantine-other-pinkHover);
  }
}

.weekend {
  color: var(--mantine-color-gray-6) !important;
}

.selected {
  background-color: var(--mantine-color-blue-8) !important;
  border-radius: 50%;

  &[data-first-in-range] {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  &[data-last-in-range] {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}

.lastDay {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.firstDay {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.range {
  background-color: #445690 !important;
  color: #fff !important;
}
