@import "./_mantine.scss";
.wrapper {
  box-shadow: 0px 4px 24px 0px #0b174e1a;
  border-radius: 8px;
  padding: 12px;
  svg {
    color: var(--mantine-color-gray-6);
  }
}

.selected {
  background-color: var(--mantine-color-blue-2);
}

.activityTitle {
  font-size: var(--mantine-font-size-md);
  font-weight: 700;
  line-height: 18px;
  color: var(--mantine-color-blue-8);
  margin-left: 3px;
}

.activityInformation {
  font-size: var(--mantine-font-size-xs);
  font-weight: 700;
  line-height: 16px;
  color: var(--mantine-color-gray-6);
}

.activityLocation {
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
  line-height: 16px;
  color: var(--mantine-color-gray-6);
}
