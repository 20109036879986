@import "./_mantine.scss";
.popoverDropdown {
  background-color: var(--mantine-color-blue-8);
  color: #fff;
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;
}

.popoverArrow {
  background-color: var(--mantine-color-blue-8);
}
