@import "./_mantine.scss";
.wrapper {
  border: var(--mantine-color-blue-2) 1px solid;
}
.wrapperError {
  border-color: var(--mantine-color-red-6);
}
.wrapperPseudoDisabled {
  pointer-events: none;
}
.input {
  border-radius: 4px;
  border: none;
  font-weight: 600;
  &::-webkit-datetime-edit-hour-field,
  &::-webkit-datetime-edit-minute-field {
    &:focus {
      background-color: var(--mantine-color-blue-2);
      color: var(--mantine-color-blue-8);
    }
  }
}
.disabledInput {
  border: none;
  opacity: 1 !important;
}
.label {
  color: var(--mantine-color-gray-6);
  font-size: var(--mantine-font-size-sm);
}
.disabledRoot {
  border: none;
  .input {
    color: black !important;
    background-color: var(--mantine-color-gray-1) !important;
    font-weight: 600;
  }
}
