@import "./_mantine.scss";
.drawer {
  border-radius: var(--mantine-radius-lg) var(--mantine-radius-lg) 0 0;
  padding-top: 16px;
}
.drawerContent {
  height: fit-content;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.drawerHeader {
  align-items: center;
  margin-inline: var(--mantine-spacing-lg);
  padding-bottom: 0;
  h2 {
    font-weight: 700;
    font-size: var(--mantine-font-size-lg);
  }
}
.drawerCloseButton {
  svg {
    color: var(--mantine-color-gray-6);
    stroke-width: 5;
  }
}
.menuItem {
  margin: var(--mantine-spacing-sm);
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;
  &[data-hovered] {
    background-color: white;
  }
}
.menuIcon {
  stroke-width: 1.5;
  path {
    fill: var(--mantine-color-blue-8);
  }
}
