@import "./_mantine.scss";
.labelsWrapper {
  flex-wrap: wrap;
}
.termBadge {
  background: var(--mantine-color-blue-2);
  text-align: center;
  color: var(--mantine-color-blue-8);
  font-weight: 400;
  height: 30px;
  border-radius: 4px;
  text-transform: none;
  &:hover {
    cursor: pointer;
  }
}
.badgeCross {
  color: var(--mantine-color-blue-8);
}
.search {
  background: var(--mantine-color-pink-5);
  color: var(--mantine-color-white);
  &:hover {
    background-color: var(--mantine-other-pinkHover) !important;
  }
}
.activityTextInput {
  background: var(--mantine-color-gray-2);
  border: none;
  height: 40px;
  color: var(--mantine-color-gray-6);
  font-size: var(--mantine-font-size-sm);
}
